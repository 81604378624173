import { React } from "react";

function App() {
  return (
    <div className="bg-cover bg-center min-h-screen flex flex-col items-center justify-center overflow-hidden">
      Wat doe je hier zo vroeg
    </div>
  );
}

export default App;
